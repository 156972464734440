import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import CommonHeaderRight from './CommonHeaderRight';
import Nav, { NavItem, NavLinkDropdown } from '../../../components/bootstrap/Nav';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import LogoImg from '../../../assets/logos/LOGO-SPPGIDMS.png';
import { navMenu } from '../../../menu';
import { useSelector } from 'react-redux';

const DefaultHeader = () => {
	const navigate = useNavigate();
	const user = useSelector((state) => state.auth?.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);

	const selectedDept = useSelector((state) => state.profile.selectedDept);

	const selectedDeptDesc = selectedDept?.Department
		? selectedDept?.Department?.description
		: selectedDept?.description;

	const isOMRDept = selectedDeptDesc === 'OMR';

	// userType: admin, doctor (employeetype: student-ug, student-pg, faculty, hod), staff
	const userTypeMenu = {
		staff: ['appointments', 'patients', 'opd', 'pharmacy', 'lab', 'accounts', 'store'],
		admin: ['appointments', 'patients', 'opd', 'pharmacy', 'staff', 'lab', 'accounts', 'store'],
		'student-ug': ['appointments', 'patients', 'opd', 'store'],
		'student-pg': ['appointments', 'patients', 'opd', 'store'],
		faculty: ['appointments', 'patients', 'opd', 'store'],
		hod: ['appointments', 'patients', 'opd', 'store'],
	};

	const userType = ['staff', 'admin'].includes(selectedOrg?.userType)
		? selectedOrg?.userType
		: user?.employeeType;

	return (
		<Header>
			<HeaderLeft style={{ paddingLeft: 0 }}>
				<Nav style={{ flexWrap: 'nowrap' }}>
					<NavItem>
						<NavLink to='/' style={{ background: 'transparent', paddingLeft: 0 }}>
							<img src={LogoImg} width={50} height={50} className='p-0' alt='logo' />
						</NavLink>
					</NavItem>
					{Object.keys(navMenu)
						?.filter((i) => userTypeMenu[userType]?.includes(i))
						?.map((item) => (
							<NavItem>
								{navMenu[item].subMenu ? (
									<Dropdown>
										<DropdownToggle>
											<NavLinkDropdown
												style={{
													color: 'black',
													paddingBottom: 0,
													paddingLeft: 0,
												}}>
												<Button
													isDisable
													isLink
													style={{ opacity: 1, fontSize: '12px' }}>
													{navMenu[item].text}
												</Button>
											</NavLinkDropdown>
										</DropdownToggle>
										<DropdownMenu>
											{navMenu[item].subMenu &&
												Object.keys(navMenu[item].subMenu).map(
													(subItem) => {
														if (subItem === 'addPatient' && !isOMRDept)
															return <></>;
														return (
															<DropdownItem>
																<NavLink
																	to={
																		navMenu[item].subMenu[
																			subItem
																		].path
																	}>
																	{navMenu[item].subMenu[subItem]
																		.icon && (
																		<img
																			className='me-2'
																			width='20px'
																			height='20px'
																			src={
																				navMenu[item]
																					.subMenu[
																					subItem
																				].icon
																			}
																		/>
																	)}
																	<span
																		style={{ color: 'black' }}>
																		{
																			navMenu[item].subMenu[
																				subItem
																			].text
																		}
																	</span>
																</NavLink>
															</DropdownItem>
														);
													},
												)}
										</DropdownMenu>
									</Dropdown>
								) : (
									<NavLinkDropdown>
										<Button
											isLink
											color='dark'
											onClick={() => navigate(navMenu[item].path)}>
											{navMenu[item].text}
										</Button>
									</NavLinkDropdown>
								)}
							</NavItem>
						))}
				</Nav>
			</HeaderLeft>
			<CommonHeaderRight afterChildren={<CommonHeaderChat />} />
		</Header>
	);
};

export default DefaultHeader;
