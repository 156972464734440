import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Navigation from '../Navigation/Navigation';
import { dashboardMenu } from '../../menu';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../components/bootstrap/OffCanvas';
import useAsideTouch from '../../hooks/useAsideTouch';
import DashboardImg from '../../assets/img/menu/patient/dashboard.png';
import { SET_CONSENT_MODAL, SET_REPORTS_MODAL } from '../../reducers/types';
import Logo from '../../assets/logos/logo.png';

// Patient MENU Icons
import ProfileImg from '../../assets/img/menu/patient/profile.png';
import Aida from '../../assets/img/menu/patient/aida.png';

import RxImg from '../../assets/img/menu/patient/rx.png';
import InvestigationsImg from '../../assets/img/menu/patient/investigations.png';
import FollowupSheetImg from '../../assets/img/menu/patient/followup-sheet.png';
import PatientFileImg from '../../assets/img/menu/patient/patient-file.png';
import BillingImg from '../../assets/img/menu/patient/billing.png';
import ReportsImg from '../../assets/img/menu/patient/reports.png';
import ClinicalNotesImg from '../../assets/img/menu/patient/clinical-notes.png';
import WhiteboardImg from '../../assets/img/menu/patient/whiteboard.png';
import AppointmentsImg from '../../assets/img/menu/patient/appointments.png';
import Reports from '../../pages/patients/components/Reports';
import GeneralConsent from '../../pages/patients/components/GeneralConsent';

const Aside = () => {
	const { asideStyle, touchStatus, hasTouchButton } = useAsideTouch();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const patient = useSelector((state) => state.patient.patient);
	const user = useSelector((state) => state.auth.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);
	const viewReports = useSelector((state) => state.modal.viewReports);
	const generalConsent = useSelector((state) => state.modal.generalConsent);

	const userTypeMenu = {
		staff: [
			'aiDa',
			'visits',
			'profile',
			'billing',
			'appointments',
			'reports',
			'whiteboard',
			'clinicalNotes',
			'generalConsent',
		],
		admin: [
			'aiDa',
			'visits',
			'profile',
			'examination',
			'treatment',
			'rx',
			'documents',
			'appointments',
			'reports',
			'billing',
			'caseSheet',
			'whiteboard',
			'clinicalNotes',
			'pathology',
			'requistionForm',
			'generalConsent',
		],
		'student-ug': [
			'aiDa',
			'profile',
			'examination',
			'treatment',
			'rx',
			'whiteboard',
			'clinicalNotes',
			'caseSheet',
			'appointments',
			'pathology',
			'requistionForm',
			'generalConsent',
		],
		'student-pg': [
			'aiDa',
			'profile',
			'examination',
			'treatment',
			'rx',
			'whiteboard',
			'clinicalNotes',
			'caseSheet',
			'appointments',
			'pathology',
			'requistionForm',
			'generalConsent',
		],
		faculty: [
			'aiDa',
			'visits',
			'profile',
			'examination',
			'treatment',
			'rx',
			'documents',
			'appointments',
			'reports',
			'caseSheet',
			'whiteboard',
			'clinicalNotes',
			'pathology',
			'requistionForm',
			'generalConsent',
		],
		hod: [
			'aiDa',
			'visits',
			'profile',
			'examination',
			'treatment',
			'rx',
			'documents',
			'appointments',
			'reports',
			'caseSheet',
			'whiteboard',
			'clinicalNotes',
			'pathology',
			'requistionForm',
			'generalConsent',
		],
	};

	const userType = ['staff', 'admin'].includes(selectedOrg?.userType)
		? selectedOrg?.userType
		: user?.employeeType;

	const asideMenu = {
		dashboard: {
			id: 'dashboard',
			text: 'Dashboard',
			path: dashboardMenu.dashboard.path,
			icon: DashboardImg,
		},
	};

	const patientAsideMenu = {
		aiDa: {
			id: 'aida',
			text: '',
			path: `/patients/${patient?.id}/aida`,
			icon: Aida,
		},
		appointments: {
			id: 'appointments',
			text: 'Appointments',
			path: `/patients/${patient?.id}/appointments`,
			icon: AppointmentsImg,
		},
		examination: {
			id: 'examination',
			text: 'Examination',
			path: `/patients/${patient?.id}/examination`,
			icon: InvestigationsImg,
		},
		treatment: {
			id: 'treatment',
			text: 'Treatment',
			path: `/patients/${patient?.id}/treatment`,
			icon: FollowupSheetImg,
		},
		rx: {
			id: 'rx',
			text: 'Prescription',
			path: `/patients/${patient?.id}/prescription`,
			icon: RxImg,
		},
		billing: {
			id: 'billing',
			text: 'Billing',
			path: `/patients/${patient?.id}/billing`,
			icon: BillingImg,
		},
		documents: {
			id: 'documents',
			text: 'Diagnostics Records',
			path: `/patients/${patient?.id}/documents`,
			icon: ReportsImg,
		},
		generalConsent: {
			id: 'generalConsent',
			text: 'Consents',
			path: () => {
				dispatch({
					type: SET_CONSENT_MODAL,
					payload: !generalConsent,
				});
			},
			icon: PatientFileImg,
			isButton: true,
		},
		caseSheet: {
			id: 'caseSheet',
			text: 'Case Sheet',
			path: `/patients/${patient?.id}/case-sheet`,
			icon: PatientFileImg,
		},
		pathology: {
			id: 'pathology',
			text: 'Pathology',
			path: `/patients/${patient?.id}/pathology`,
			icon: PatientFileImg,
		},
		requistionForm: {
			id: 'requistionForm',
			text: 'Requistion Form',
			path: `/patients/${patient?.id}/requistion-form`,
			icon: PatientFileImg,
		},
		whiteboard: {
			id: 'whiteboard',
			text: 'Whiteboard',
			path: `/patients/${patient?.id}/whiteboard`,
			icon: WhiteboardImg,
		},
		clinicalNotes: {
			id: 'clinicalNotes',
			text: 'Clinical Notes',
			path: `/patients/${patient?.id}/clinical-notes`,
			icon: ClinicalNotesImg,
		},
		visits: {
			id: 'visits',
			text: 'Visits',
			path: `/patients/${patient?.id}/visits`,
			icon: AppointmentsImg,
		},
		profile: {
			id: 'profile',
			text: 'Profile',
			path: `/patients/${patient?.id}/profile`,
			icon: ProfileImg,
		},
		reports: {
			id: 'reports',
			text: 'Reports',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_REPORTS_MODAL,
					payload: !viewReports,
				}),
			icon: PatientFileImg,
		},
	};

	const sideMenu = {};
	Object.keys(patientAsideMenu)
		?.filter((i) => userTypeMenu[userType]?.includes(i))
		.forEach((x) => (sideMenu[x] = patientAsideMenu[x]));

	return (
		<>
			<motion.aside
				style={{ ...asideStyle, zIndex: 1 }}
				className={classNames('aside text-center shadow-none', {
					'aside-touch-bar': hasTouchButton && isModernDesign,
					'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
					'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
				})}>
				<div className='aside-body' style={{ marginTop: '7rem' }}>
					<Navigation menu={asideMenu} id='aside-dashboard' />
					{pathname.includes(`/patients/${patient?.id}`) && (
						<Navigation menu={sideMenu} id='aside-dashboard' />
					)}
				</div>
				<small className='px-1 py-2 fw-bold text-muted'>
					Powered by <br />
					<img src={Logo} height={30} />
				</small>
			</motion.aside>

			<OffCanvas setOpen={() => {}} isOpen={viewReports} isBodyScroll placement='end'>
				<OffCanvasHeader
					setOpen={() =>
						dispatch({
							type: SET_REPORTS_MODAL,
							payload: !viewReports,
						})
					}></OffCanvasHeader>
				<OffCanvasBody>
					<Reports />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={generalConsent}
				isBodyScroll
				placement='end'
				width='70%'>
				<OffCanvasHeader
					setOpen={() =>
						dispatch({
							type: SET_CONSENT_MODAL,
							payload: !generalConsent,
						})
					}></OffCanvasHeader>
				<OffCanvasBody>
					<GeneralConsent />
				</OffCanvasBody>
			</OffCanvas>
		</>
	);
};

export default Aside;
